import { _axiosapi } from '../_axios';
//惠医保主页-账户总余额
export const homePage  =  (params) => {
    return _axiosapi({
        url: `/member/homepage/totalBalance`,
        method: "POST",
        data:{request:params},
    })
}
//适用门店-离您最近
export const drugStoreOne = (params) =>{
    return _axiosapi({
        url:'/mall/drug/store/store/nearby/one',
        method:'POST',
        data:{request:params}
    })
}
//适用门店列表
export const drugStoreList = (params )=>{
    return _axiosapi({
        url:'/mall/drug/store/list/store/nearby',
        method:'POST',
        data:{request:params}
    })
}
//获取签名
export const configs = (params) =>{
    return _axiosapi({
        url:'/system/config/setting/h5/to/Js/configs',
        method:'POST',
        data:{request:params}
    })
}