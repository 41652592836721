<template>
  <div class="store">
    <top title="适用门店"></top>
    <div class="contain" ref="box">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div
          class="storelist"
          v-for="item in list"
          :key="item.id"
          @click="map(item.locationY, item.locationX, item.sotreName)"
        >
          <div class="pic">
            <img :src="item.coverImg" alt="" />
          </div>
          <div class="address">
            <p>
              <span>{{ item.sotreName }}</span>
              <span><i class="iconfont icon-daohang"></i></span>
            </p>
            <p>{{ item.storeAddress }}</p>
            <div class="photo">
              <i class="iconfont icon-daohangdizhi"></i>
              <p style="color:#999;margin-left:4px">{{ item.diatance }}km</p>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script type="text/javascript" src="http://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<script>
import {configs, drugStoreList} from '@/api/_api/home'
export default {
  data() {
    return {
      list: [],
      listed: {},
      loading: false,
      finished: false,
      refreshing: false,
      lat: sessionStorage.getItem("lat")||0.00,
      lng: sessionStorage.getItem("lng")||0.00,
      page: 1,
      length: 0,
      isLoading: true,
    };
  },
  mounted() {
    this.getConfig().then((res) => {
      const data = res;
      wx.config({
        debug: false,
        appId: "wxd7f1c8ad7bd591cc",
        timestamp: data.timeStamp,
        nonceStr: data.randomStr,
        signature: data.signature,
        jsApiList: ["openLocation", "getLocation"],
      });
      wx.ready(function (res) {
        console.log(res)
        console.log('初始化js-sdk成功');
        wx.checkJsApi({
          jsApiList: ['getLocation', 'openLocation'],
          success(res) {
            if (res.checkResult.getLocation === false) {
              const message = '你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！'
              reject(message);
            } else {
              const message = 'js-sdk检查成功';
              console.log(message);
              resolve(message);
            }
          },
          fail(res) {
            const message = 'checkJsApi fail=' + JSON.stringify(res);
            console.log(message);
            reject(message);
          }
        });
      });
      wx.error(function ({res}) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
        console.log(res);
      });
    });
  },
  methods: {
    getConfig() {
      return new Promise((resolve) => {
        //   let url = location.href.split('#')[0].replace(/s/,'')
        //   url = encodeURIComponent(url)
        let url = encodeURIComponent(location.href.split('#')[0]);
        configs({ url }).then((res) => {
          if (res.code == 200) {
            resolve(res.data);
          }
        });
      });
    },
    map(lng, lat, name) {
      if(lat&&lng){
        // this.$router.push({ path: "/map", query: { storename: name, lng: lng, lat: lat } });
        wx.openLocation({
          latitude : parseFloat(lat), // 纬度，浮点数，范围为90 ~ -90
          longitude : parseFloat(lng), // 经度，浮点数，范围为180 ~ -180。
          name : name, // 位置名
          address : name, // 地址详情说明
          scale : 24, // 地图缩放级别,整形值,范围从1~28。默认为最大
          infoUrl : 'http://www.gongjuji.net' // 在查看位置界面底部显示的超链接,可点击跳转（测试好像不可用）
        });
      }else{
        this.$toast.fail("定位失败，请重新获取定位")
      }
    },
    onLoad() {
      this.$toast.loading({
        message:'加载中...',
        forbidClick:true,
      })
      let params = {
        locationX:this.lng,
        locationY:this.lat,
        pageNo:this.page,
        pageSize:'10'
      }
      drugStoreList(params).then(res=>{
        this.$toast.clear();
        if(res.code == 200){
          this.length = res.data.totalNum;
          res.data.list && res.data.list.forEach(item=>{
            sessionStorage.setItem('storeAddress',item.storeAddress)
            item.diatance = item.diatance.toFixed(1)
            item.coverImg?item.coverImg = JSON.parse(item.coverImg)[0].url:''
          })
          this.loading = false
          this.list = this.list.concat(res.data.list)
          if (this.list.length >= this.length) {
            this.finished = true;
          }
          this.page++;
        }
      })



      // 异步更新数据
      // getlocation(this.lat, this.lng, this.page, 30).then((res) => {
      //   this.length = res.data.data.total;
      //   res.data.data.list.forEach((item) => {
      //     this.listed = {
      //       address: item.address,
      //       name: item.orgName,
      //       Lat: item.locationX,
      //       Lng: item.locationY,
      //       diatance: item.diatance.toFixed(2),
      //       id: item.id,
      //       orgId: item.orgId,
      //     };
      //     this.list.push(this.listed);
      //   });
      //   this.isLoading = false;
      //   // 加载状态结束
      //   this.loading = false;
      //   // 数据全部加载完成
      //   if (this.list.length >= this.length + 1) {
      //     this.finished = false;
      //   }
      //   this.page++;
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.store {
  width: 100%;
  height: 100%;
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .contain {
    width: 100%;
    height: vw(1230);
    background: #f9f9f9;
    overflow-y: auto;
    .storelist {
      display: flex;
      margin: vw(28);
      align-items: center;
      background: #fff;
      border-radius: vw(8);
      .pic {
        width: vw(172);
        height: vw(172);
        margin-right: vw(24);
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
          border-radius:vw(8);
        }
      }
      .address {
        width: vw(482);
        p {
          line-height: vw(50);
          &:first-child {
            display: flex;
            justify-content: space-between;
            span {
              &:first-child {
                font-size: vw(32);
                // font-weight: 600;
              }
              &:last-child {
                width: vw(50);
                height: vw(50);
                i {
                  color: $indexcolor;
                  width: vw(20);
                }
              }
            }
          }
          &:nth-child(2) {
            font-size: vw(28);
            color: #333;
            width: vw(482);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .photo {
          display: flex;
          align-items: center;
          height: vw(45);
          // align-self: center;
          i {
            font-size: vw(28);
            color: #999;
          }
          p {
            font-size: vw(28);
            vertical-align: bottom;
          }
        }
      }
    }
  }
}
</style>
